<template>
    <div class="detail-control">
        <div class="detail-banner">
            <van-image width="100%" height="100%" :src="detailData.good_pic" fit="cover"/>
        </div>
        <div class="detail-info">
            <div class="detail-info-top flex-between">
                <van-skeleton title :loading="loading" title-width="100%" style="width: 50%">
                    <div class="flex">
                        <div class="price-1" v-if="detailData.is_sale_off == 0">
                            ¥<strong>{{ detailData.price.split('.')[0] }}</strong>
                            <span>.{{ detailData.price.split('.')[1] }}</span>
                        </div>
                        <div class="price-1" v-else>
                            ¥<strong>{{ detailData.qyk_price.split('.')[0] }}</strong>
                            <span>.{{ detailData.qyk_price.split('.')[1] }}</span>
                            <img src="https://keyang2.tuzuu.com/klfront/064d6454f4ac90be076b821d9d33f0e7.png"
                                 v-if="detailData.goods_type == 1"/>
                        </div>
                        <div class="price-2">
                            ¥{{ detailData.is_sale_off === 0 ? detailData.price_show : detailData.price }}
                        </div>
                    </div>
                </van-skeleton>
                <van-skeleton title :loading="loading" title-width="100%" style="width: 30%">
                    <div class="flex info-num">
                        <img src="https://keyang2.tuzuu.com/klfront/10c779a5f07c891908c81a7c8d4b33f6.png"/>
                        <span>{{ detailData.buy_num }}人抢购</span>
                    </div>
                </van-skeleton>

            </div>
            <div class="detail-info-title">
                <van-skeleton :row="2" :loading="loading">
                    {{ detailData.title }}
                </van-skeleton>
            </div>
        </div>
        <van-tabs v-model="tabActive" scrollspy sticky line-width="60" title-inactive-color="#999"
                  title-active-color="#232323">
            <van-tab title="产品介绍">
                <div class="instru">
                    <div class="instru-con" v-html="detailData.detail"></div>
                </div>

            </van-tab>
            <van-tab title="使用规则">
                <div class="instru">
                    <div class="instru-title">
                        使用规则
                    </div>
                    <div class="instru-con" v-html="detailData.used_rules"/>
                </div>
            </van-tab>
            <van-tab title="适用人群">
                <div class="instru">
                    <div class="instru-title">
                        适用人群
                    </div>
                    <div class="instru-con" v-html="detailData.used_group"/>

                </div>
            </van-tab>
        </van-tabs>

        <div class="goods-action">
            <div class="goods-action-item" @click="kefuShow = true">
                <img src="https://keyang2.tuzuu.com/klfront/e8bdc30822f8b16d11d813ed884540cd.png"/>
                <span @click="kefuShow = true">客服</span>
            </div>
            <div class="goods-action-item" @click="showPoster = true">
                <img src="https://keyang2.tuzuu.com/klfront/f430259c177aa68b1280fb3a9d204954.png"/>
                <span @click="showPoster = true">海报</span>
            </div>
            <div class="goods-action-button flex-center" @click="goPay">
                立即预订
            </div>
        </div>

        <!--客服二维码-->
        <ServicePopup v-model="kefuShow"
                      :kf_qrcode="detailData.kf_qrcode || 'https://keyang2.tuzuu.com/klfront/1577ffe7dc2d295c718ef4c1e9de4ab8.png'"/>
        <Posters :poster="posters" v-if="showPoster" @downPosters="downPosters" title-color="#232323"
                 desc-color="#666666"/>
    </div>
</template>

<script>
import ServicePopup from "@/components/ServicePopup";
import Posters from "@/components/posters"
import wxShare from "@/wechat/weChatTile";

export default {
    components: {
        ServicePopup,
        Posters
    },

    data() {
        return {
            loading: true,
            tabActive: 0,
            kefuShow: false,
            showPoster: false,
            posters: {},
            detailData: {
                price: '0.00',
                qyk_price: '0.00'
            }
        }
    },
    async created() {
        let res = await axios.get('/qykshop/good_detail', {
            params: {
                goods_id: this.$route.params.id
            }
        })
        if (res.code === 1) {
            this.detailData = res.data
            this.loading = false
            let fid = window.localStorage.getItem("userid")
            //海报
            this.posters = {
                presonImage: this.detailData.poster[0].headimgurl,
                presonName: this.detailData.poster[0].nickname,
                info: this.detailData.poster[0].poster_desc || '分享好物，传递健康',
                href: window.location.origin + window.location.pathname + `?fid=${fid}`,
                shut: 'https://khome2.tuzuu.com/klfront/close.png',
                backUrl: this.detailData.poster[0].poster
            }
            if (this.posters.backUrl.length === 0) {
                this.posters.backUrl = ['']
            }

            document.title = res.data.title
            wxShare({
                title: res.data.title,
                desc: res.data.short_title || '康来家园邀你共享健康家园',
                link: window.location.origin + window.location.pathname + `?fid=${fid}`,
                imgUrl: res.data.good_pic
            })
        }
    },

    methods: {
        goPay() {
            let id = this.$route.params.id
            if (this.detailData.is_activation === 1 && this.detailData.goods_type === 2) {
                this.$router.push({
                    path: `/ydj/pay-ydj-success/${id}`,
                    query: {
                        state: '1'
                    }
                })
            } else {
                this.$router.push({
                    path: `/ydj/pay-order/${id}`,
                    query: this.$route.query
                })
            }
        },
        // 海报
        downPosters() {
            this.showPoster = false
        },
    }
}
</script>

<style scoped lang="less">
.detail-control {
    padding-bottom: 65px;

    .detail-banner {
        width: 100%;
        padding-top: 56%;
        position: relative;

        .van-image {
            position: absolute;
            left: 0;
            top: 0;
        }
    }

    .detail-info {
        background: #FFFFFF;
        border-radius: 15px 15px 0 0;
        padding: 14px 16px;
        margin-top: -14px;
        position: relative;
        border: 1px solid #F1F1F1;
        z-index: 1;

        .van-skeleton {
            padding: 0;

            .van-skeleton__row, .van-skeleton__title {
                height: 20px;
            }
        }

        .detail-info-top {
            .van-skeleton__row, .van-skeleton__title {
                height: 30px;
            }

            div:first-of-type {
                align-items: flex-end;
            }

            .price-1 {
                color: #ED2525;
                font-size: 14px;
                margin-right: 11px;
                position: relative;

                strong {
                    font-size: 27px;
                }

                span {
                    font-weight: 700;
                }

                img {
                    width: 36px;
                    position: absolute;
                    right: -20px;
                    top: -3px;
                }
            }

            .price-2 {
                font-size: 14px;
                text-decoration: line-through;
                font-weight: 500;
                color: #A0A0A0;
                line-height: 25px;
            }

            .info-num {
                font-size: 14px;
                font-weight: 500;
                color: #232323;

                img {
                    width: 20px;
                    margin-right: 5px;
                }
            }
        }

        .detail-info-title {
            font-size: 17px;
            font-weight: bold;
            color: #232323;
            margin-top: 9px;
            line-height: 27px;
        }
    }

    /deep/ .van-sticky {
        .van-tabs__wrap {
            height: fit-content;
        }

        .van-tabs__nav {
            padding-top: 14px;
        }

        .van-tab--active {
            font-weight: 700;
            position: relative;
            z-index: 2;
        }

        .van-tabs__line {
            background: linear-gradient(270deg, #FFDECF 0%, #FC5B33 100%);
            border-radius: 2px;
            bottom: 18px;
        }
    }

    .instru {
        width: 100%;
        display: block;
        padding: 0px 15px;
        box-sizing: border-box;

        .instru-title {
            font-weight: 700;
            font-size: 15px;
            margin-bottom: 5px !important;
        }

        .instru-con {
            padding-bottom: 10px;
            font-size: 12px;
            color: black;
            word-break: break-all;
            border-bottom: 3px solid #f5f5f5;

            /deep/ img {
                max-width: 100% !important;
                display: block;
            }

            &:last-of-type {
                border-bottom: none;
            }
        }
    }

    .goods-action {
        position: fixed;
        bottom: 0;
        background: #FFFFFF;
        box-shadow: 0px -3px 10px 0px rgba(224, 224, 224, 0.27);
        width: 100%;
        box-sizing: border-box;
        padding: 10px 15px 7px 30px;
        display: flex;
        gap: 30px;

        .goods-action-item {
            display: flex;
            align-items: center;
            flex-direction: column;
            font-size: 14px;
            font-weight: 500;
            color: #4C4C4C;

            img {
                width: 20px;
                margin-bottom: 6px;
            }
        }

        .goods-action-button {
            flex: 1;
            background: linear-gradient(90deg, #FF6435 0%, #ED2C2C 100%);
            border-radius: 40px;
            font-size: 15px;
            font-weight: bold;
            color: #FFFFFF;
        }
    }
}

</style>